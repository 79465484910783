<template>
  <div />
</template>

<script>
export default {
  mounted () {
    this.$emit('adjust:height', -70)
  }
}
</script>

<style>
</style>
